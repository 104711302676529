import React from "react";
import "./Home.css";
import programmerImage from "./Img/3094291.png";

function Home() {
  return (
    <div className="home body px-4 pt-32 lg:px-40">
      <div className="home-container grid place-content-center">
        <div className="name-details grid-row-2 grid place-content-center lg:gap-8">
          <h1 className="text-center text-6xl font-medium lg:font-bold">Hi</h1>
          <h1 className="text-center text-6xl font-medium lg:font-bold">
            I'm Giridhar
          </h1>
        </div>
      </div>
      <div>
        {/*
                <div className="profile-container">
                    <div><img src={logo} style={{width:"230px"}}></img></div>
                    <div className="text-container"><h5>Im giri</h5></div>
                </div>
            */}
        <div className="tag-line text-center lg:px-40">
          <p className="tag-line-content text-xl">
            Passionate web developer dedicated to crafting innovative digital
            experiences. Combining technical expertise with creativity to bring
            ideas to life in the digital landscape.
          </p>
        </div>
        <div className="grid grid-cols-1 gap-14 lg:grid-cols-3 lg:pt-20">
          <div className="lg:col-span-1">
            <img src={programmerImage} alt="javascript frameworks"></img>
          </div>
          <div className="about-home grid place-items-center rounded-xl bg-white lg:col-span-2">
            <div>
              <h2 className="about-home-h2 lg:pt-18 px-8 pb-4 pt-8 text-5xl font-bold">
                About
              </h2>
              <p className="lg:pb-18 pb-8 pl-8 pr-8 text-lg md:pl-14">
                I'm a passionate web developer who creates flawless digital
                experiences. My path is converting concepts into dynamic,
                eye-catching web pages. Equipped with technological expertise
                and a steadfast dedication to staying ahead of the constantly
                changing digital scene, I like transforming ideas into engaging
                virtual worlds.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <h2 className="px-8 pb-4 pt-8 text-center text-5xl font-bold lg:pt-24">
          Testimonials
        </h2>
        <div className=" grid grid-cols-1 place-items-center gap-4 pt-4 md:grid-cols-2 lg:grid-cols-3 lg:pt-8">
          <div className="card-1 rounded-2xl">
            <p className="px-8 pt-8">
              It was a joy working with Giridhar. He demonstrated remarkable
              coding and problem-solving capabilities in our class projects.
              They were a creative addition to the team, always working together
              to make our initiatives stand out.
            </p>
            <h6 className="px-8 pb-8 text-end">- Name1</h6>
          </div>
          <div className="card-1 rounded-2xl">
            <p className="px-8 pt-8">
              Giridhar is a fantastic team player in addition to being a gifted
              web developer. They showed a thorough comprehension of front-end
              and back-end technology during our schooling. Their eagerness to
              cooperate and exchange expertise had a big influence on our group
              endeavors.
            </p>
            <h6 className="px-8 pb-8 text-end">- Name1</h6>
          </div>
          <div className="card-1 rounded-2xl">
            <p className="px-8 pt-8">
              Having collaborated with Giridhar on several projects, I can
              genuinely attest to their exceptional technical proficiency. They
              have an amazing ability to understand complex requirements and
              translate them into websites that are both functional and easy to
              use. Giridhar is a trustworthy and knowledgeable expert.
            </p>
            <h6 className="px-8 pb-8 text-end">- Name1</h6>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Home;
