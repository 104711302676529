import React from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Home from "./Components/Home";
import AboutMe from "./Components/AboutMe";
import Skills from "./Components/Skills";

function App() {
  return (
    <div className="App">
      <header>
        <Router>
          <div className="navBar">
            <div className="router-contents mx-4 my-2 flex h-14 w-fit items-center justify-center gap-8 px-16 lg:w-fit lg:gap-20">
              <div>
                <Link to="/" className="link">
                  <svg
                    id="logo"
                    data-name="Layer 1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 2209.57 1175.54"
                  >
                    <polygon
                      class="logo"
                      points="533.35 272.12 0 587.77 533.35 903.42 533.35 794.58 200.98 587.77 533.35 391.85 533.35 272.12"
                    />
                    <polygon
                      class="logo"
                      points="1676.23 272.12 2209.57 587.77 1676.23 903.42 1676.23 794.58 2008.6 587.77 1676.23 391.85 1676.23 272.12"
                    />
                    <path
                      class="logo"
                      d="M-473.27-395.26V315.66l108.46-108.85V-315.65L-88.42-119.72l98-76.2L-367.91-451.2C-412.75-481.53-473.27-449.4-473.27-395.26Z"
                      transform="translate(1104.57 587.76)"
                    />
                    <path
                      class="logo"
                      d="M419.1,156c-18.52-10-39.53-14.46-60.57-14.46h-202V250.35H375.73V381H-125q.24-87.33.49-174.66l500.2-519.9V61h98V-415.49L639.3-587.76h0a315.57,315.57,0,0,0-225.81,95.13L-641.2,587.78h0A353.31,353.31,0,0,0-386.5,479.32l161.35-167.84,1.59,63.42c.7,28,11.58,55.16,31.6,74.77l1.05,1c19.53,18.68,46.06,28.24,73.09,28.24H375.73c3.22,0,6.35-.12,9.38-.34,50.54-3.68,88.58-47.95,88.58-98.63V250.35C473.69,197.62,445.31,170.12,419.1,156Z"
                      transform="translate(1104.57 587.76)"
                    />
                    <path
                      class="logo"
                      d="M362.45-60.68,149.2-58.39q21-21.6,42.08-43.22H362.45Z"
                      transform="translate(1104.57 587.76)"
                    />
                  </svg>
                </Link>
              </div>
              <div>
                <Link to="/about" className="link">
                  About Me
                </Link>
              </div>
              <div>
                {/*<Link to="/skills" className="link">
                  Skills
                </Link>*/}
              </div>
              {/* <div>
                <Link to="/projects" className="link">
                  projects
                </Link>
  </div>*/}
            </div>
          </div>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/about" element={<AboutMe />} />
            {/* <Route path="/skills" element={<Skills />} />*/}
          </Routes>
        </Router>
      </header>
    </div>
  );
}

export default App;
