import React from "react";
import "./AboutMe.css";
import responsiveprogrammer from "./Img/Aboutme 1.png";
import responsivedesigner from "./Img/About 2.png";
function AboutMe() {
  return (
    <div className="body px-4 pb-8 md:pb-14 lg:px-24">
      <h1 className="mb-14 px-4 pt-32 text-center text-5xl md:text-6xl">
        I'm Giridhar M A
      </h1>
      <div className="grid grid-cols-1 place-content-center gap-8 pb-8 lg:grid-cols-3 lg:gap-0 lg:pb-20">
        <div className="col-span-1 grid place-items-center lg:w-72">
          <img src={responsiveprogrammer} alt="img1"></img>
        </div>
        <div className="gridtext col-span-2 grid place-content-center rounded-xl">
          <p className="px-8 py-4 text-right text-xl">
            I'm a driven web developer with a talent for bringing concepts to
            life on screen. I have a solid background in front-end and back-end
            technologies, and my area of expertise is building beautiful,
            user-friendly websites that are easy to navigate and make an impact.
          </p>
        </div>
      </div>
      <div className="grid grid-cols-1 place-content-center gap-8 lg:grid-cols-3 lg:gap-20 lg:pb-20">
        <div className="gridtext col-span-2 grid place-content-center rounded-xl">
          <h2 className="px-8 pb-4 text-4xl font-bold ">
            Why Develop Websites?
          </h2>
          <p className="px-8 py-4 text-xl">
            Ever since I came across HTML and CSS for the first time, I've been
            fascinated by how code can turn ideas into interactive experiences.
            What began as a simple curiosity swiftly turned into a committed
            exploration of the constantly changing field of web development. I
            enjoy the challenges that come with being on the cutting edge of
            technology, and I'm always looking for new ways to improve my
            abilities.
          </p>
        </div>
        <div className="col-span-1 lg:relative lg:h-72 lg:w-96">
          <div>
            <img
              class="hidden lg:absolute lg:-bottom-8 lg:-right-8 lg:block"
              src={responsivedesigner}
              alt="img1"
            ></img>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 place-content-center gap-8 lg:grid-cols-2 lg:pb-8">
        <div className="gridtext col-span-1 grid place-content-center rounded-xl">
          <h2 className="px-8 pb-4 pt-8 text-4xl font-bold ">
            What Makes Me Unique
          </h2>
          <p className="px-8 py-4 pb-8 text-xl">
            What makes me unique is my dedication to providing an experience
            rather than just a product, which goes beyond the code. I take a
            user-centric approach to every project, making sure that the
            finished result not only satisfies technical requirements but also
            goes above and beyond what users could ask for. Being a
            collaborative person by nature, I do best in group settings where
            creativity is valued and ideas are allowed to flow freely.
          </p>
        </div>
        <div className="gridtext col-span-1 rounded-xl">
          <h2 className="px-8 pb-4 pt-8 text-4xl font-bold ">
            Continuous Learning
          </h2>
          <p className="px-8 py-4 pb-8 text-xl">
            I think that lifelong learning is crucial in the dynamic field of
            web development. I welcome any chance to broaden my knowledge and
            skill set, whether it be through participating in coding
            competitions, trying out new technologies, or keeping up with the
            most recent developments in the field.
          </p>
        </div>
      </div>
    </div>
  );
}
export default AboutMe;
